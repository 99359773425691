
import { defineComponent } from "vue";
import InputText from "@/components/atoms/inputs/InputText.vue";

export default defineComponent({
  name: "EmailVerifySection",
  data() {
    return {
      formDataToSend: {
        confirm: "",
      },
      email: "",
    };
  },
  components: {
    InputText,
  },
  mounted() {
    this.sendForm();
  },
  methods: {
    sendForm() {
      this.$store.dispatch("getNFTs/updateUser").then(
        (response) => {
          this.email = response.data.email;
          this.sendVerificationEmail();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    sendVerificationEmail() {
      this.$store
        .dispatch("auth/verificationEmail", {
          email: this.email,
        })
        .then(
          (res) => {
            console.log(res);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    verifyEmail() {
      const { formDataToSend } = this;
      console.log(formDataToSend.confirm, "dataEmail");
      this.$store
        .dispatch("auth/verifyEmail", {
          code: formDataToSend.confirm,
        })
        .then(
          (res) => {
            console.log(res);
            this.$router.push("/dashboard/profile");
          },
          (error) => {
            console.log(error)
          }
        );
    },
    resend() {
      this.sendVerificationEmail();
    },
  },
});
